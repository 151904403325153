
@tailwind base;
@tailwind components;
@tailwind utilities;

    /* font: 'Montserrat', sans-serif;
    color-hex: #4e6e81;
    color-rgb: rgba(78, 110, 110);
    soft background color: rgba(216, 230, 239, 1)
    border-radius: 10px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3); 
    soft border: 0.5px solid rgb(211,211,211);
    form
    border: 1px solid rgb(211,211,211);
    border-radius: 5px;
    */


@layer base {
    body {
        font: 'Montserrat', sans-serif;
    }
}

@layer components {

  .button,
  .input-button {
    @apply cursor-pointer border-1.5 border-dark-basic bg-dark-basic  text-white font-montserrat rounded-md py-2 px-5 text-sm transition-all duration-300 hover:bg-white hover:text-dark-basic;
  }

  .show-advance-filter-button {
    @apply rounded-md py-[10px] px-[20px] cursor-pointer border-1.5 border-dark-basic bg-dark-basic text-white transition duration-300 hover:bg-white hover:text-dark-basic;
  }

  .card-container {
    @apply bg-soft-basic w-[350px] h-[302ox] rounded-xl shadow-[0_2px_5px_0_rgba(78,110,110,0.3)] ;
  }

  .container-login-reset-password-register {
    @apply flex flex-col justify-center items-center mt-[150px] mb-[150px]
  }

  .container-login-for-container-reset-password-form-container-register-form {
    @apply flex flex-col justify-center w-[450px] max-md:w-[350px] max-sm:w-[350px] rounded-md border shadow-box-shadow p-[30px]
  }

  .form-container-login-reset-password-register {
    @apply flex flex-col gap-[20px] mt-[40px]
  }

  .profile-img {
    @apply rounded-full xl:w-60 xl:h-60 md:w-40 md:h-40 max-sm:w-36 max-sm:h-36 shadow-box-shadow
  }

  .cancel-button {
    @apply border-0.5 rounded-md py-[7px] px-2 cursor-pointer bg-red-600 text-white
    }
}

body { 
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    color: #4e6e81;
}

a {
    text-decoration: none;
    color: #4e6e81;
}

li {
    list-style: none;
}

.disable-scroll {
    overflow: hidden;
}

/* .App {
    display: grid;
    grid-template-columns: 500px 1000px;
    grid-template-rows: 100px 100px 100px;
} */


/* NAVIGATION HEADER */
.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
    border-bottom: 0.5px solid rgb(211,211,211);
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3);
    align-items: flex-end;
    z-index: 9999;
}


.navbar-menu {
    display: inline-flex;
    align-items: center;
    list-style: none;
    gap: 2rem;
    text-decoration: none;
    margin-bottom: 20px;
}

.navbar-menu > li a {
    text-decoration: none;
    /* font-weight: bold; */
}

.profile-menu-container {
    position: relative;
    cursor: pointer;
}


.company-profile-menu,
.company-job-panel-menu-action {
    width: 120px;
    position: absolute;
    top: 120%; /* Position the menu just below the avatar */
    left: 0;
    list-style-type: none;
    padding: 0;
    margin: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 100; /* Ensure the menu appears above other elements */
}

.company-profile-menu{
    width: 130px;
}

.company-profile-menu li,
.company-job-panel-menu-action li {
    padding: 8px;
    cursor: pointer;
}

.company-profile-menu li:hover,
.company-job-panel-menu-action li:hover {
    background-color: rgba(216, 230, 239, 1);
}

#post-job-menu {
    display: flex;
    gap: 5px;
    background-color: rgba(216, 230, 239, 1);
}

/* .button,
.input-button,
.company-bio-button {
    border: 1.5px solid #4e6e81;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background 0.3s;
    background-color: #4e6e81;
    color: white;
}

.button:hover,
.input-button:hover,
.company-bio-button:hover {
    background-color: white;
    opacity: 0.9;
    color: #4e6e81;
} */

.disabled-button {
    border: 1.5px solid #4e6e81;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: not-allowed;
    transition: background 0.3s;
    background-color: #4e6e81;
    color: white;
}


.profile-introduction-save-button {
    border: 1.5px solid #4e6e81;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background 0.3s;
    background-color: #4e6e81;
    color: white;
}

.profile-introduction-save-button:hover{
    background-color: white;
    opacity: 0.9;
    color: #4e6e81;
}

#profile-introduction-save-cancel-button {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.profile-introduction-cancel-button {
    border: 1.5px solid red;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    /* transition: background 0.3s; */
    background-color: red;
    color: white;
    /* margin-top: 10px; */
    /* position: relative; */
    /* bottom: 49px; */
    /* left: 80px; */
}

.profile-location-container {
    margin-top: 10px;
}

.profile-location {
    display: flex;
    gap: 5px;
}

.location {
    align-self: flex-end;
}

.location-selection {
    display: flex;
}

.image-rate-name {
    display: flex;
    flex-direction: column;
}


.input-button-change-image {
    /* display: flex; */
    border: 1.5px solid #4e6e81;
    border-radius: 5px;
    padding: 6px 6px;
    cursor: pointer;
    transition: background 0.3s;
    background-color: #4e6e81;
    color: white;
    font-size: 13px;
    align-self: flex-end;
}

.input-button-change-image:hover {
    background-color: white;
    opacity: 0.9;
    color: #4e6e81;
}


/* FILTER BOX ------------------------------------------------------------------------------------- */
.filter-box {
    justify-content: flex-start;
    padding: 3px 10px;
    width: 450px;
    border: 0 1px 0 0;
    box-shadow: 5px 0px 10px 0px rgba(78, 110, 110, 0.3);
}


.filter-box ul {
    margin-top: 10%;
    margin-right: 10%;
}

.addEducation-close-button {
    justify-content: flex-end;
    color: #4e6e81;
    position: relative;
    left: 95%;
    top: 0.4%;
}

.search-menu {
    /* background-color: cyan; */
    display: inline-flex;
    width: 100vw;
    margin-top: 6px;
    align-items: center;
    flex-direction: column;
}

.search-input {
    margin-top: 2rem;
}

.search-input > ul {
    display: inline-flex;
    gap: 1rem;
    /* background-color: red; */
}

/* SEARCH BAR */
.container-search{
    display: flex;
}

.clear-advanced-filter {
    position: relative;
    left: 43%;
    cursor: pointer;
}

/* USER LIST ----------------------------------------------------------------------------------------------------- */
.container-user-list,
.container-jobs-list {
    display: flex;
    width: 95vw;
    padding-top: 10px;
    padding-left: 55px;
    flex-direction: row;
    align-items: flex-start;
    flex-direction: column;
}

.container-user-list-75vw,
.container-jobs-list-75vw {
    width: 75vw;
}

.user-list,
.jobs-list {
    display: flex;
    justify-content: flex-start;
    gap: 40px;
    flex-wrap: wrap;
    margin: 2%;
    /* background-color: red; */
}

.user-list-loader-container {
    z-index: 9999;
    position: absolute;
    display: flex;
    display: flex;
    justify-content: center;
    /* align-self: center; */
    /* align-items: center; */
    width: 99vw;
    height: 80vh;
    margin: 20px auto 20px auto;
    /* background:rgba(216, 230, 239, 0.6);  */
}

.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    align-self: center;
    border-top: 4px solid #4e6e81;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-left: 4px solid #4e6e81;
    border-bottom: 4px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
  }
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 

/* User Card */
.user-card {
    background-color: #d8e6ef;
    width: 350px;
    height: 376px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 0px rgba(78, 110, 110, 0.3);
}

.user-card-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 9999;
    width: 350px;
    height: 196px;
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 600 600'%3E%3Cfilter id='a'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23a)'/%3E%3C/svg%3E");
  }

.add-emp-type-section {
    display: flex;
    gap: 10px;
    margin-top: 50px;
    margin-bottom: 10px;
}

.portfolio-edit {
    display: flex;
    flex-direction: row;
    gap: 10px;
}


.skill-selection {
    width: 170px;
    gap: 20px;
    height: 2.25rem;
    font-size: 15px;
    /* border: 1px solid #4e6e81;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3); */
}

.user-rate-status {
    background-color: #d8e6ef;
    height: 35px;
    border-radius: 0 0 10px 10px;
}

.user-rate-status ul {
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
    padding: 0;
}

.user-rate-status ul li {
    /* background-color: blue; */
    display: inline-flex;
}

.user-rate-status ul li p {
    margin: 8px auto;
}

.user-rate {
    background-color: red;
    height: 0px;
}

.user-list-rating {
    /* background-color: red; */
    position: relative;
    left: 60px;
}

.tooltip {
    /* background-color: blue; */
    width: 90px;
}

.user-full-name {
    /* background-color: red; */
    width: 130%;
}

/* Portfolio */
.portfolio-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    /* background-color: red; */
}

.portfolio {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background-color: blue; */
    height: 40px;
    gap: 10px
}

.portfolio a {
    font-size: 23px;
}

.portfolio-edit-delete-button {
    display: flex; 
    justify-content: center;
    /* background-color: yellow; */
    gap: 10px;
}


/* Footer  -------------------------------------------------------------------------*/
.container-footer {
    display: flex;
    /* justify-content: center;
    flex-wrap: wrap; */
    /* height: 200px; */
    /* background-color: blue; */
    /* box-shadow: 0 0 5px 0px rgba(78, 110, 110, 0.9); */
    flex-direction: column;
    align-items: center;
    background-color: #d8e6ef;
    padding-top: 30px;
    flex-wrap: wrap;
}


.container-contact-info {
    display: flex;
    /* width: 40vw; */
    padding: 5rem auto;
    /* background-color: red; */
    justify-content: center;
    /* margin-right: 10%; */
}

.container-contact-info ul li{
    display: flex;
    margin-top: 10px;
    font-size: 18px;
}

.container-contact-info div ul li a {
    text-decoration: none;
    color: #4e6e81;
}

.social-media {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-start;
    /* background-color: blue; */
    padding: 0;
}

.sendEmailInput  {
    width: 450px;
    height: 25px;
    font-size: 15px;
    border: 1px solid #4e6e81;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3);
}

.sendEmailTextArea {
    width: 450px;
    font-size: 15px;
    border: 1px solid #4e6e81;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3);
}

.account-credential {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
}

/* PAGINATION ---------------------------------------------------------------------- */

.container-pagination {
    margin: 50px auto;
    display: flex;
    justify-content: center;
}


/* ICON COLOR ---------------------------------------------------------------------- */

/* Rating star color */
.fa-star, .fa-solid {
    color: #4e6e81;
}

/* ERROR --------------------------------------------------------------------------- */
.error-field {
    color: red;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.add-education-alert {
    /* background-color: red; */
    position: relative;
    bottom: 20px;
}



/* ALERT --------------------------------------------------------------------------- */
.alert {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 9999;
}

/* LOGIN PAGE ---------------------------------------------------------------------- */

.container-username,
.container-password,
.container-retype-password,
.container-fullname,
.container-email {
    display: flex;
    flex-direction: column;
}

/* HAPUS */
.login-input,
.new-passport-input,
.input-field,
.rate-input-field {
    gap: 20px;
    max-width: 90%;
    height: 2rem;
    font-size: 15px;
    border: 1px solid rgb(211,211,211);
    border-radius: 5px;
}

.login-input:focus,
.new-passport-input:focus,
.input-field:focus,
.portfolio-input-field:focus,
.rate-input-field:focus {
    outline: none
}

.reset-password-input {
    gap: 20px;
    width: 100%;
    height: 2rem;
    font-size: 15px;
    border: 1px solid rgb(211,211,211);
    border-radius: 5px;
}

.reset-password-input:focus{
    outline: none
}

.login-input-resend-activation {
    gap: 20px;
    width: 100%;
    height: 2rem;
    font-size: 15px;
    border: 1px solid rgb(211,211,211);
    border-radius: 5px;
}

.login-input-resend-activation:focus{
    outline: none
}

.experience-input-field {
    gap: 20px;
    max-width: 100%;
    height: 2rem;
    font-size: 15px;
    border: 1px solid rgb(211,211,211);
    border-radius: 5px;
}

.experience-input-field:focus{
    outline: none;
}

.portfolio-input-field {
    width: 60%;
    height: 2.25rem;
    outline: none;
}

.rate-input-field {
    width: 20%;
    height: 2.25rem;
    outline: none;
}

.portfolio-selection {
    gap: 20px;
    width: 180px;
    height: 2.47rem;
    font-size: 15px;
    border: 1px solid rgb(211,211,211);
    border-radius: 5px;
}

.password-input-field {
    gap: 20px;
    width: 90%;
    height: 2rem;
    font-size: 15px;
    border: 1px solid rgb(211,211,211);
    border-radius: 5px;
    /* box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3); */
    outline: none;
}

.login-button-loginpage {
    margin-top: 20px;
    margin-bottom: 5px;
}

.password-input,
.password-field-container {
    display: flex;
    gap: 10px;
}

.eye-icon {
    align-self: center;
    width: 10px;
}


/* USER TYPE */

.container-user-type {
    /* background-color: red; */
    display:  flex;
    flex-direction: column;
    align-items: center;
}

/* .user-type-header {
    background-color: blue;
} */

.emp-type-user-list ul {
    display: flex;
    flex-direction: row;
    /* background-color: red; */
}

.emp-type-user-list li {
    display: flex;
    flex-direction: row;
    /* background-color: red; */
    align-self: flex-end;
    padding: 0px;
}

.emp-type-user-list span {
    display: flex;
    flex-direction: row;
}

/* Hide the actual radio button input */
input[type="radio"] {
    display: none;
}

/* Style the radio button when it's selected */
input[type="radio"]:checked + .button-label {
    background-color: #4e6e81;
    border-color: #4e6e81;
    color: #fff;
}

/* USER ACTIVATION ---------------------------------------------------------------------- */
.container-user-activation {
    display: flex;
    /* background-color: blue; */
    justify-content: center;
    margin: 10% auto;
}
.activation-confirmation {
    padding: 100px 100px;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3);
    
    /* margin: 100px 100px 20% 100px; */
}

.signup-button {
    /* background-color: red; */
    margin-top: 10px;;
    width: 100%;
    border: 1.5px solid #4e6e81;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background 0.3s;
    background-color: #4e6e81;
    color: white;
}

.signup-button:hover {
    background-color: white;
    opacity: 0.9;
    color: #4e6e81;
}


/* PROFILE PAGE */

.profile-and-job-section {
    /* display: grid;
    grid-template-columns: 1fr 20px 1fr;
    margin-top: 50px;
    border: 0px solid #4e6e81;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3); 
    padding: 30px */
}

.profile-section {
    /* padding-right: 50px; */
    /* background-color: red; */
    /* padding: 20px; */
    /* box-shadow: 5px 0 5px -2px rgba(78, 110, 110, 0.3); */
}

.profile-content {
    /* background-color: red; */
    padding-left: 20px;
}


.change-progress-image-button {
    display: flex;
    /* flex-direction: column; */
    align-self: flex-end;
}

/* .job-container {
    border-left: 1px solid rgba(169,169,169, 0.5);
    box-shadow: -5px 0px 5px -5px rgba(169, 169, 169, 0.5);
} */

.profile_action {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    gap: 20px;
    /* justify-content: space-evenly; */
}

/* Profile Rate */

.rate-stars {
    display: flex;
    align-self: center;
    position: relative;
    right: 80px;
}

/* Profile Image */
.profile_picture {
    display: flex;
    align-items: center;
    gap: 20px;
}

.profile_image {
    /* background-color: blue; */
    width: 250px;
    height: 250px;
    border-radius: 50%;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3);
}

/* Total Payment */
.total_payment {
    /* margin-left: 100px; */
    /* background-color: cyan; */
    border: 1px solid #4e6e81;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3);
}

/* Profile Detail */


.profileTextArea {
    width: 90%;
    font-size: 15px;
    border: 1px solid rgb(211,211,211);
    border-radius: 5px;
}

.experience-textArea {
    max-width: 100%;
    font-size: 15px;
    border: 1px solid rgb(211,211,211);
    border-radius: 5px;
}

.experience-textArea:focus{
    outline: none;
}

.checkbox-input {
    align-self: center;
}

.jobTitle p {
    font-size: 18px;
    font-weight: bold;
}

.experience-title {
    /* background-color: red; */
    width: 150%;
}

.edit-delete-exp-button {
    display: flex;
    gap: 10px;
}

.experience-date-list {
    /* background-color: blue; */
    position: absolute;
    right: 60px;
    top: 17px;
    display: flex;
    justify-content: space-between;
}

.experience-date-list p {
    font-size: 15px;
    width: 90px;
    justify-content: space-around;
    /* background-color: red; */
}

.profile_experience,
.experience-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #ffff;
}

.profile_experience,
.skills-container,
.expected-rate-container,
.portfolio-container,
.profile_education,
.profile-employment-type,
.profile_language {
    padding: 5px;
}

.experience-date {
    display: flex;
    gap: 10px;
}

.experience-startend-date {
    display: flex;
    flex-direction: column;
}

.addExperience-close-button {
    align-self: flex-end;
    color: #4e6e81;
    position: relative;
}

#add-experience-wrapper {
    width: 900px;
    height: 480px;
    border-radius: 10px;
    background-color: #ffff;
}

.profile-input-field {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    outline: none;
}

/* JGN DIHAPUS */
.add-education {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* background-color: red; */
    padding: 10px 10px;
    gap: 10px;
    width: 40%;
    height: 95%;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3);
}


.education-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* background-color: red; */
}

.profile-edit-delete-button {
    display: flex;
    gap: 10px;
    /* background-color: cyan; */
    padding-top: 15px;
}

.education-item,
.portfolio-item {
    width: 100%;
}

.education-edit-button {
    display: flex;
    /* width: 50vw; */
    /* background-color: red; */
}

.edit-field {
    display: flex;
    flex-direction: column;
}


/* LANGUAGE SECTION */

/* .profile_language,
.profile_experience,
.portfolio-container,
.skills-container,
.profile_education,
.profile-employment-type {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 0px solid #4e6e81;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3);
    padding: 30px;
} */

.language-selection {
    gap: 20px;
    width: 10rem;
    height: 2.25rem;
    font-size: 15px;
    border: 1px solid rgb(211,211,211);
    border-radius: 5px;
}

.language-proficiency-selection {
    gap: 20px;
    width: 10rem;
    height: 2.25rem;
    font-size: 15px;
    border: 1px solid rgb(211,211,211);
    border-radius: 5px;
}

.language-save-button,
.skill-save-button {
    border: 1.5px solid #4e6e81;
    border-radius: 5px;
    padding: 5px 20px;
    cursor: pointer;
    transition: background 0.3s;
    background-color: #4e6e81;
    color: white;
}

.language-save-button:hover,
.skill-save-button:hover {
    background-color: white;
    opacity: 0.9;
    color: #4e6e81;
}

.skills-container {
    /* background-color: red; */
    /* margin-top: 30px; */
}

.language-list,
.skill-level-list,
.emp-type-list {
    /* background-color: red; */
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /* width: 50em; */
}

.language-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3);
    background-color: #d8e6ef;
    /* font-size: 15px; */
}

.skill-level-container,
.emp-type-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3);
    background-color: #d8e6ef;
}

.emp-type-container {
    flex-direction: row;
}

.emp-type-list-close-button {
    position: relative;
    float: left;
    margin-left: 20px;
    /* top: 10px */
    /* width: 20%; */
}


.language-list-close-button,
.skill-list-close-button {
    position: relative;
    float: left;
    /* top: 10px */
    width: 50%;
}

.language-item,
.skill-item {
    background-color: #d8e6ef;
}

.language-proficiency-item,
.skill-level-item {
    align-self: flex-end;
    margin-top: 10px;
    margin-left: 50px;
}

/* Expected rate */


.expected-rate {
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* background-color: red; */
}

.amount-expected-rate {
    background-color: rgba(216, 230, 239, 1);
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px; 
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3); 
}

.currency-expected-rate-form {
    position: relative;
    bottom: 5px;
}

.currency-expected-rate-form p {
    font-size: 20px;
    position: relative;
    bottom: 5px;
}

.period-expected-rate {
    width: 10%;
}

.expected-rate-form {
    display: flex;
    gap: 10px;
}

.divider {
    width: 0;
    height: 100%;
    margin: 0; /* Remove default margin for the hr element */
    position: relative;
    border: 1px solid #F2F1EB;
}


/* MESSAGES ----------------------------------------------------------------- */
.messages-container {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    margin: 5% 10% 20% 0%;
}

.skills-menu:hover,
.rate-menu:hover,
.experience-menu:hover,
.portfolio-menu:hover,
.education-menu:hover,
.emp-type-menu:hover,
.language-menu:hover,
.resume-menu:hover {
    cursor: pointer;
    background-color: rgba(216, 230, 239, 1);
    transition: background-color 0.5s;
}

.inbox-menu-clicked,
.sent-menu-clicked,
.deleted-menu-clicked{
    padding: 10px;
    border: 1px solid rgb(211,211,211);
    background-color: rgba(216, 230, 239, 1);
}

.inbox-menu-content {
    display: flex;
    justify-content: space-between;
    /* background-color: blue; */
    height: 20px;
}

.inbox-menu-content p {
    /* background-color: red; */
    align-self: center;
}

.inbox-menu,
.sent-menu,
.inbox-menu-clicked,
.sent-menu-clicked {
    border-bottom: 0px;
}

.inbox {
    display: grid;
    grid-template-columns: 15% 70% 15%;
    padding-left: 10px;
    background-color: rgba(216, 230, 239, 1);
    border: 0.1px solid rgba(128, 128, 128, 0.2);
    height: 100px;
    line-height: 35px;
    white-space: wrap-words;
}

.inbox:hover {
    cursor: pointer;
    box-shadow: 1px 5px 45px -29px #4e6e81 inset;
}

/* DO NOT DELETE, NOT USE TAILWIND */
.inbox-read {
    display: grid;
    grid-template-columns: 15% 70% 15%;
    padding-left: 10px;
    background-color: rgba(240, 238, 239, 0.8);
    border: 0.1px solid rgba(128, 128, 128, 0.2);
    height: 100px;
    line-height: 35px;
    white-space: wrap-words;
}

/* DO NOT DELETE, NOT USE TAILWIND */
.inbox-read:hover {
    cursor: pointer;
    box-shadow: 1px 5px 45px -29px #4e6e81 inset;
}

.date-container,
.inbox-delete-button {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    align-items: center;
    /* background-color: red; */
}

.message-detail-container {
    z-index: 99;
    position: fixed; /* Use fixed position to cover the entire viewport */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
}

.message-detail-container-reply {
    z-index: 99;
    position: fixed; /* Use fixed position to cover the entire viewport */
    top: 0;
    left: 0;
    bottom: 100px;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
}

.message-detail {
    padding: 2%;
    z-index: 100;
    position: absolute;
    top: 6%;
    left: 13%;
    width: 70vw;
    background-color: #ffff;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3); 
}

.message-detail-reply {
    padding: 2%;
    z-index: 100;
    position: absolute;
    top: 6%;
    left: 13%;
    width: 70vw;
    overflow-y: visible;
    background-color: #ffff;
}

.message-detail-reply-expanded {
    background-color: red;
    padding: 2%;
    z-index: 100;
    position: absolute;
    top: 6%;
    left: 13%;
    width: 70vw;
    overflow-y: visible;
    background-color: #ffff;
}

.message-detail-prevEmailExpanded {
    background-color: red;
    padding: 2%;
    z-index: 100;
    position: absolute;
    top: 6%;
    left: 13%;
    width: 70vw;
    height: 140%;
    overflow-y: visible;
    background-color: #ffff;
}

.message-detail-close-button {
    position: relative;
    left: 100%;
    bottom: 25px;
}

.sender-date-message {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.send-message-textarea-reply {
    width: 100%;
    font-size: 15px;
    border: 1px solid #4e6e81;
    border-radius: 5px;
    outline: none;
}

.reply-cancel-button {
    background-color: red;
    color: white;
    border: 1px solid red;
    border-radius: 5px;
    padding: 6.5px 15px;
    position: relative;
    left: 80px;
    bottom: 38.5px;
    cursor: pointer;
}

.sender-message,
.date-message {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.sender-body-message {
    width: 100%;
    height: 450px;
    background-color: rgba(216, 230, 239, 0.6);
    overflow-y: scroll;
}

.form-replay {
    display: flex;
    flex-direction: column;
}

.body-content {
    padding: 10px;
}

#sender-name {
    font-size: 25px;
    /* background-color: blue; */
    align-self: flex-end;
}

#sender-name > u:hover{
    cursor: pointer;
}

#sender-email {
    font-size: 14px;
    align-self: flex-end;
    /* background-color: red; */
}
.message-replay {
    display: flex;
    /* background-color: red; */
    justify-content: flex-end;
    gap: 20px;
}

.message-reply-button {
    align-content: flex-start;
    /* background-color: red; */
}

/* DO NOT DELETE, NOT USE TAILWIND */
.refresh-icon {
    transition: transform 0.4s ease;
}

.refresh-icon:hover {
    transform: scale(1.2);
}

.show-email-rotated {
    transform: rotate(180deg);
}

/* PAGINATION */

.pagination-container {
    display: flex;
    gap: 200px;
    justify-content: space-between;
}

.pagination-container a{
    align-self: center;
}

.number-li {
    /* padding: 12px; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 13px;
    padding-right: 13px;
    background-color: rgba(216, 230, 239, 1);
    transition: background 0.3s;
}

.number-li:hover {
    cursor: pointer;
    opacity: 0.9;
    background-color: #4e6e81;
    color: #fff;
}

.page-number-clicked {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 13px;
    padding-right: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #4e6e81;
    transition: background 0.3s;
    color: #fff;
    cursor: pointer;
}

/* SEARCH */

/* .search-box {
    width: 500px;
    height: 50px;
    max-width: 100%;
    border-radius: 30px;
    box-shadow: 0 1px 5px 0px rgba(78, 110, 110, 0.3);
} */

.searchResultList-container {
    z-index: 9999999;
    margin-top: 10px;
    margin-left: 10px;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* gap: 20px; */
    width: 460px;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    /* border: 0.5px solid #4e6e81; */
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3);
}

/* .skill-div {
    height: 120px;
    overflow-y: auto;
} */

.searchResultList-item,
.searchResultList-item-many-more {
    background-color: #ffff;
    /* height: 40px; */
    border: 1px solid rgba(216, 230, 239, 1);
    cursor: pointer;
    align-items: center;
    padding: 10px;
}

.searchResultList-title {
    background-color: rgba(216, 230, 239, 1);
    /* height: 40px; */
    border: 1px solid rgba(216, 230, 239, 1);
    align-items: center;
    padding: 10px;
    font-style: italic;
}

.searchResultList-item:hover {
    box-shadow: 1px 5px 45px -29px #4e6e81 inset;
}

.searchResultList-item-many-more {
    cursor:auto;
}

/* NOT FOUND */
.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70lvh;
}

.not-found-container p {
    font-size: 10em;
    font-weight: bolder;
    margin-bottom: 0;
    margin-top: 0;
}

/* COMPANY PROFILE */

.rate-name-company-profile {
    margin-left: 10px;
    align-self: flex-start;
    width: 600px;
    /* background-color: blue; */
}

.company-phone-email {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.company-address {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: relative;
    width: 350px;
    /* background-color: red; */
}

.company-phone-email>div,
.company-address>div {
    display: flex;
    gap: 10px;
    /* background-color: blue; */
}

.company-phone-email>div>span,
.company-address>div>span {
    /* background-color: red; */
    align-self: center;
}

.company-bio-container {
    margin-top: 50px;
    width: 1000px;
    padding: 20px;
}

#job-posted-title-button {
    display: flex;
    justify-content: space-between;
}

#add-job-button {
    align-self: center;
}

#edit-button-company-profile {
    /* background-color: red; */
    align-self: flex-end;
}

.company-profile-cancel-button {
    border: 1px solid red;
    border-radius: 5px;
    background-color: red;
    color: white;
    padding: 6.6px 8px;
    cursor: pointer;
}

#company-profile-form-container-in {
    height: 100%;
    overflow-y: auto;
}

#company-profile-form-container-in::-webkit-scrollbar{
    width: 0 !important;
}

.company-profile-form {
    display: flex;
    flex-direction: column;
    padding: 50px;
    gap: 10px;
}

.company-bio-input {
    height: 35px;
    border: 0.5px solid rgb(211,211,211);
    /* border: 1px solid #4e6e81;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3); */
}

.company-bio-input-text-area,
.company-bio-input-select {
    border: 0.5px solid rgb(211,211,211);
    /* border: 1px solid #4e6e81;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3); */
}

.company-bio-input:focus,
.company-bio-input-text-area:focus,
.company-bio-input-select:focus{
    outline: none;
}

.company-bio-input-select {
    height: 100%;
}


#company-profile-action-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
}

.backdrop-background {
    z-index: 99999999999;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background:rgba(216, 230, 239, 0.6); */
    position: fixed; 
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
}

.company-profile-circular-progress {
    color: '#fff';
    z-index: 9999;
}

/* ADD JOB */


#job-title {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 90%;
    /* background-color: red; */
}

#job-title>p{
    align-self: center;
}

#add-job-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

#posted-page-add-job-form-container,
#posted-page-edit-job-form-container {
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background:rgba(216, 230, 239, 0.6);
    position: fixed; /* Use fixed position to cover the entire viewport */
    width: 100lvw;
    height: 100vh;
    top: 0;
    left: 0;
}

#job-posted-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffff;
    width: 70lvw;
    height: 90lvh;
    margin-top: 10px;
    overflow-y: auto;
    border: 0px solid #4e6e81;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3);
}

#job-posted-form::-webkit-scrollbar{
    width: 0 !important;
}

.input-title-container {
    /* display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%; */
    /* background-color: yellow; */
}


.add-job-form-title>div {
    padding-left: 30px;
    padding-right: 30px;
}

.add-job-form-title p{
    font-weight: bold;
    margin-top: 0;
}

.add-job-input-field {
    gap: 20px;
    width: 98%;
    height: 2rem;
    font-size: 15px;
    border: 1px solid rgb(211,211,211);
    border-radius: 5px;
    outline: none;
}

.add-job-textarea-field {
    gap: 20px;
    width: 98%;
    max-width: 98%;
    font-size: 15px;
    border: 1px solid rgb(211,211,211);
    border-radius: 5px;
}


#add-job-input-field-salary {
    gap: 20px;
    width: 90%;
    height: 2.35rem;
    font-size: 15px;
    border: 0.5px solid rgb(211,211,211);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-left: none;
    outline: none;
}

#add-job-input-field-salary:focus,
.add-job-textarea-field:focus {
    outline: none;
}

#add-job-salary-selection {
    width: 50%;
}

#add-new-job-button {
    align-self: flex-end;
    display: flex;
    gap: 10px;
}

.currency-sign-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
    height: 2.35rem;
    font-size: 15px;
    border: 0.5px solid rgb(211,211,211);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-right: none;
}

.currency-input-field-container {
    display: flex;
    width: 50%;
}

#add-job-backdrop-container {
    background-color: transparent;
}

#job-posted-list-title-container {
    display: flex;
    align-items: center;
    padding: 10px;
    height: 30px;
}

#job-posted-list-title-container>li{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 30px;
    border-bottom: 0.5px solid rgb(211,211,211);
}

/* #job-container {
    width: 1000px;
    display: flex;
    flex-direction: row;
    border: .5px solid rgb(211,211,211);
    padding: 10px;
} */
/* 
#job-skills{
    width: 100%;
    background-color: red;
} */

/* #job-skills>ul{
    padding-left: 0;
    background-color: red;
} */

#job-basic-info{
    width: 150%;
    padding-bottom: 10px;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#job-basic-info>a:hover{
    text-decoration: underline;
}

#job-basic-info>p{
    margin-top: 0px;
    margin-bottom: 0px;
}

#job-basic-info div{
    display: flex;
    gap: 10px;
}

#job-date-action-button{
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 70%;
    position: relative;
    justify-content: center;
    gap: 15px;
}

#job-date-action-button>p{
    margin-top: 0px;
    margin-bottom: 0px;
    /* background-color: red; */
}

#job-date-action-button>div{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

#job-date-action-button>*{
    padding-right: 20px;
}

#job-posted-pagination-button {
    display: flex;
    justify-content: space-between;
}

#job-posted-pagination-button>p{
    cursor: pointer;
}

#job-status-open {
    background-color: #65B741;
    color: #ffff;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
}

#job-status-closed {
    background-color: rgb(211,211,211);
    color: #ffff;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
}

#job-status-onprogress {
    background-color: rgb(228, 168, 107);
    color: #ffff;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
}

#job-status-finished {
    background-color: #5d4a26;
    color: #ffff;
    padding: 2px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 5px;
}

/* JOB DETAIL */
/* #job-detail-section {
    display: flex;
    justify-content: center;
}

#job-detail-container {
    background-color: #ffff;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 5em;
    margin-bottom: 5em;
    width: 70%;
    border: 0px solid #4e6e81;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3);
} */

/* #job-already-applied-status-container {
    display: flex;
    justify-content: center;
} */

/* #job-already-applied-status-layout {
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(216, 230, 239, 1);
    border: 0px solid #4e6e81;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3);
} */

/* #job-time-status {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-left: 50px;
    margin-right: 50px;
} */

/* #job-detail-layout{
    display: flex;
    flex-direction: column;
} */

/* #job-detail {
    display: flex;
    padding: 30px;
} */

/* #job-detail-header,
#job-detail-action-button {
    margin-left: 50px;
    margin-right: 50px;
} */

/* #job-detail-company-name:hover{
    text-decoration: underline;
} */

/* .job-detail-icon-info {
    display: flex;
    align-items: center;
    gap: 10px;
} */

#job-detail-description {
    margin-left: 50px;
    margin-right: 50px;
}

#loved-counter {
    display: flex;
    /* background-color: red; */
    /* justify-content: flex-end; */
    /* margin-top: 5px; */
    /* margin-bottom: 20px; */
}

#loved-counter>span{
    align-self: center;
}

/* EDIT JOB */
#edit-job-section {
    display: flex;
    justify-content: center;
}

#edit-job-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffff;
    width: 70lvw;
    height: 90lvh;
    margin-top: 10px;
    border: 0px solid #4e6e81;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3);
}

#add-job-form-container {
    overflow-y: auto;
}

#add-job-form-container::-webkit-scrollbar{
    width: 0 !important;
}

#panel-profile-content-image-edit-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* NO DELETE */
.profile-info {
    display: grid;
    grid-template-columns: 0.2fr 1fr;
}

#job-panel-more-button {
    display: flex;
    position: absolute;
    right: 18%;
}

#jobs-panel-add-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* JOB DETAIL PANEL */

#job-label-data-container {
    display: grid;
    /* background-color: red; */
    /* padding-right: 100px; */
    grid-template-columns: 0.2fr 1fr;
}

#job-label-data-container>ul{
    padding-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
}

#job-detail-panel-back-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#job-detail-panel-back-button>div{
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 20px;
}

/* JOB LIST */
#job-card {
    background-color: #d8e6ef;
    width: 350px;
    height: 372px;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3);
}


.job-list-deadline p {
    margin: 0 0px;
    padding: 0;
    padding-left: 20px;
    font-size: 15px;
    /* text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);; */
}

.not-login-action-sign-up-button,
.not-login-action-login-button {
    width: 400px;
    height: 40px;
    border: 1.5px solid #4e6e81;
    border-radius: 5px;
    /* padding: 10px 20px; */
    cursor: pointer;
    transition: background 0.3s;
    background-color: #4e6e81;
    color: white;
}

@media not all and (min-width: 640px) {
    .not-login-action-sign-up-button,
    .not-login-action-login-button{
        width: 280px
    }
}

.not-login-action-login-button{
    background-color: rgba(216, 230, 239, 1);
    border: 1px solid rgba(216, 230, 239, 1);
    color: #4e6e81;
}

.not-login-action-sign-up-button:hover,
.not-login-action-login-button:hover{
    background-color: #ffff;
    opacity: 0.9;
    color: #4e6e81;
}

.not-login-action-login-button:hover{
    border-color: #4e6e81;
}

/* DELETION CONFIRMATION */

#delete-confirmation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

#delete-confirmation-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3); 
}

#delete-confirmation-button-action{
    display: flex;
    gap: 10px;
}

.delete-confirmation-yes-button {
    border: 1.5px solid red;
    border-radius: 5px;
    padding: 6.2px 20px;
    cursor: pointer;
    background-color: red;
    color: white;
}

/* INTERESTED USER */

#list-status {
    background-color: #4e6e81;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3); 
    cursor: pointer;
    transition: background 0.3s;
    color: #ffff;
}

#list-status:hover{
    background-color: rgba(216, 230, 239, 1);
    color: #4e6e81;
}

#default-selected-status {
    background-color: rgba(216, 230, 239, 1);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3); 
    cursor: pointer;
    transition: background 0.3s;
    color: #4e6e81;
}

.interested-user-profile-action-status-accepted {
    padding: 10px;
    background-color: green;
    color: #ffff;
    border-radius: 5px;
}

#interested-user-profile-detail-wrapper {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 50px;
    height: 93%;
    overflow-y: auto;
}

#interested-user-profile-detail-wrapper::-webkit-scrollbar{
    width: 0;
}

#interested-user-profile-detail {
    display: grid;
    grid-template-columns: 0.3fr 1.7fr;
    gap: 50px;
}

#interested-user-profile-detail>ul {
    display: flex;
    gap: 10px;
    padding-left: 0;
    
}

#interested-user-profile-detail>ul>li{
    border: 0.5px solid rgb(211,211,211);
    padding: 5px;
    border-radius: 3px;
    background-color: rgb(211,211,211, 0.5);
}

#interested-user-profile-name {
    align-self: center;
    width: 100%;
}

#interested-user-profile-detail-experiences{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

#interested-user-profile-detail-portfolio-list>a {
    display: flex;
    align-items: flex-end;
}

#interested-user-profile-detail-language-list {
    display: flex;
    gap: 7px;
}

#interested-user-profile-detail-language-list>div>p{
    margin-bottom: 0px;
}

#interested-user-profile-detail-language-wrapper {
    background-color: red;
}

.item-interested-user-profile-detail {
    padding-top: 10px;
}

.eliminated-status,
.accepted-status,
.whitelist-status,
.applied-status,
.reviewed-status,
.withdraw-status {
    color: #ffff;
    padding-right: 9px;
    padding-left: 9px;
    padding-top: 6.6px;
    padding-bottom: 6.7px;
    border-radius: 5px;
}

.applied-status {
    color: #4e6e81;
    background-color: rgba(216, 230, 239, 1);
    border: 1px solid rgba(216, 230, 239, 1);
}

.reviewed-status {
    background-color: #7C93C3;
}

.eliminated-status {
    background-color: red;
}

.whitelist-status {
    background-color: #ffff;
    color: #4e6e81;
    border: 1px solid #4e6e81;
}

.accepted-status {
    background-color: green;
}

.withdraw-status {
    background-color: #000000;
    color: #ffff;
}

/* INTERESTED JOBS */

#interested-jobs-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#interested-jobs-wrapper {
    margin: 50px auto;
    /* background-color: red; */
    padding: 20px;
    width: 70lvw;
    height: 700px;
    border: 0px solid #4e6e81;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3); 
}

#interested-job {
    display: grid;
    grid-template-columns: 1.3fr 0.7fr;
    border: 0.1px solid rgba(128, 128, 128, 0.2);
    width: 98%;
}

#interested-job-detail>h2:hover,
#interested-job-detail>span:hover{
    cursor: pointer;
    text-decoration: underline;
}

#interested-jobs-status-filter-wrapper {
    display: flex;
    justify-content: flex-end;
}

#interested-jobs-status-filter {
    width: 150px;
}

.interested-job-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 75%;
    width: 100%;
    overflow-y: auto;
    /* background-color: red; */
}

.interested-job-no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 75%;
}

/* NOTIFICATION */

#notification-menu{
    cursor: pointer;
}



#notification-message-container::-webkit-scrollbar{
    width: 0px;
}



#notification-message-wrapper>ul{
    padding-left: 0;
}

#notification-message-read {
    display: flex;
}

#notification-message-unread {
    background-color: rgba(216, 230, 239, 0.7);
}

#notification-message-list {
    display: flex;
    flex-direction: column;
    padding: 10px;
}

#notification-message-list:hover{
    cursor: pointer;
    box-shadow: 1px 5px 45px -29px #4e6e81 inset;
}

#notification-message{
    margin: 0px;
}

#notification-message-loading-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 450px;
}

#notification-message-created-date {
    align-self: flex-end;
    font-size: 13px;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    z-index: 99999999
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #4e6e81;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #4e6e81 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  

  /* ADD RATING */


  #rating-detail-container {
    /* width: 1000px; */
    border: .5px solid rgb(211,211,211);
    padding: 10px;
  }

  #rating-detail-container > h2 {
    margin-bottom: 0;
  }

  #comment-wrapper {
    display: flex;
    gap: 20px;
  }

  #user-rating{
    /* background-color: red; */
    margin-left: 40px;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 95px;
  }

  #rating-detail-container>p>b:hover{
    text-decoration: underline;
    cursor: pointer;
  }

  #add-rating-container {
    position: fixed;
    width: 100lvw;
    height: 100lvh;
    background-color: rgba(216, 230, 239, 0.5);
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #rating-title-close-icon > p {
    display: flex;
    justify-content: center;
  }

  .rating-comment-input {
    width: 100%;
    max-height: 90px;
    max-width: 100%;
    /* height: 30px; */
  }

  #add-rating-wrapper>form{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  #add-rating-wrapper>form>span{
    align-self: flex-start;
  }

  #rating-selection {
    display: flex;
    flex-direction: column;
  }

  #close-icon-add-rating {
    align-self: flex-end;
  }


  /* PROFILE MENU */

/* HAPUS NANTI */
.profile-menu-action {
    height: 100%;
    overflow-y: scroll;
}
/* HAPUS NANTI */
.profile-menu-action::-webkit-scrollbar{
    width: 0px;
}

.skills-menu,
.rate-menu,
.experience-menu,
.portfolio-menu,
.education-menu,
.emp-type-menu,
.language-menu,
.resume-menu {
    text-align: center;
    width: 180px;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3); 
    border: 0px;
    padding: 10px;
}

.skills-menu-clicked,
.rate-menu-clicked,
.experience-menu-clicked,
.portfolio-menu-clicked,
.education-menu-clicked,
.emp-type-menu-clicked,
.language-menu-clicked,
.resume-menu-clicked {
    text-align: center;
    width: 180px;
    border-radius: 10px;
    box-shadow: 0 2px 5px 0px rgba(78, 110, 110, 0.3); 
    border: 0px;
    cursor: pointer;
    background-color: rgba(216, 230, 239, 1);
    padding: 10px;
}

#job-detail-skills-skeleton {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

#job-detail-posted-on-skeleton {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

#job-skills-company-profile-skeleton {
    display: flex;
    gap: 10px;
}

#company-profile-skeleton-pagination {
    display: flex;
    gap: 10px;
}

/* NEWWWWWWWWWWWW */
#salary-skeleton > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.user-skills-list-skeleton ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    margin-top: 0;
    gap: 10px;
    margin-bottom: 0;
}

.user-skills-list-skeleton ul li {
    padding: 2px 10px;
    background-color: #d8e6ef;
    border-radius: 10px;
    margin-bottom: 10px;
}

.container-user-list-skeleton {
    display: flex;
    width: 95vw;
    padding-top: 10px;
    padding-left: 55px;
    /* height: 600px; */
    /* margin: 20px auto 20px auto; */
    flex-direction: row;
    align-items: flex-start;
    /* align-content: center; */
    /* background-color: blue; */
    flex-direction: column;
}

.transition-menu {
    transition: width 0.3s ease-in-out; /* Adjust the duration and easing as needed */
  }

.custom-select {
    z-index: 9999;
}

/* SUCCESS SEND APPLICATION */
.success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;
    
    .check-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #4CAF50;
        
        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }
        
        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
            animation: rotate-circle 4.25s ease-in;
        }
        
        &::before, &::after {
            content: '';
            height: 100px;
            position: absolute;
            background: #FFFFFF;
            transform: rotate(-45deg);
        }
        
        .icon-line {
            height: 5px;
            background-color: #4CAF50;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;
            
            &.line-tip {
                top: 46px;
                left: 14px;
                width: 25px;
                transform: rotate(45deg);
                animation: icon-line-tip 0.75s;
            }
            
            &.line-long {
                top: 38px;
                right: 8px;
                width: 47px;
                transform: rotate(-45deg);
                animation: icon-line-long 0.75s;
            }
        }
        
        .icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(76, 175, 80, .5);
        }
        
        .icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: #FFFFFF;
        }
    }
}

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}
